// deno-lint-ignore-file

/* global console, localStorage, location, Office, window */

import { Configuration, LogLevel, PublicClientApplication, RedirectRequest } from "@azure/msal-browser";
import * as jose from "jose";
// import { showMessage } from "./message-helper.ts";

const clientId = "022acbeb-f227-43ac-aed7-34c15f0be247"; //This is your client ID
const accessScope = `api://templates.affinity-fire.com/${clientId}/access_as_user`;
const loginRequest: RedirectRequest = {
  scopes: [accessScope],
  extraScopesToConsent: ["User.Read", "User.Read.All"],
  // extraScopesToConsent: ["User.Read", "User.Read.All", "Sites.Read.All"],
};

const msalConfig: Configuration = {
  auth: {
    clientId: clientId,
    authority: "https://login.microsoftonline.com/29db2def-278a-4559-be1e-c9d4586cad1a",
    // redirectUri: "https://templates.affinity-fire.com/fallbackauthdialog.html", // Update config script to enable `https://${window.location.host}/fallbackauthdialog.html`,
    redirectUri: `https://${window.location.host}/fallbackauthdialog.html`, // Update config script to enable `https://${window.location.host}/fallbackauthdialog.html`,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage", // Needed to avoid "User login is required" error.
    storeAuthStateInCookie: true, // Recommended to avoid certain IE/Edge issues.
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

const publicClientApp: PublicClientApplication = new PublicClientApplication(msalConfig);

let loginDialog: Office.Dialog = null;
let homeAccountId = null;

Office.onReady(() => {
  if (Office.context.ui.messageParent) {
    publicClientApp
      .handleRedirectPromise()
      .then(handleResponse)
      .catch((error) => {
        console.log(error);
        Office.context.ui.messageParent(JSON.stringify({ status: "failure", result: error }));
      });

    // The very first time the add-in runs on a developer's computer, msal.js hasn't yet
    // stored login data in localStorage. So a direct call of acquireTokenRedirect
    // causes the error "User login is required". Once the user is logged in successfully
    // the first time, msal data in localStorage will prevent this error from ever hap-
    // pening again; but the error must be blocked here, so that the user can login
    // successfully the first time. To do that, call loginRedirect first instead of
    // acquireTokenRedirect.
    if (localStorage.getItem("loggedIn") === "yes") {
      publicClientApp.acquireTokenRedirect(loginRequest);
    } else {
      // This will login the user and then the (response.tokenType === "id_token")
      // path in authCallback below will run, which sets localStorage.loggedIn to "yes"
      // and then the dialog is redirected back to this script, so the
      // acquireTokenRedirect above runs.
      publicClientApp.loginRedirect(loginRequest);
    }
  }
});

function handleResponse(response) {
  if (response.tokenType === "id_token") {
    console.log("LoggedIn");
    localStorage.setItem("loggedIn", "yes");
  } else {
    console.log("token type is:" + response.tokenType);
    Office.context.ui.messageParent(
      JSON.stringify({
        status: "success",
        result: response.accessToken,
        accountId: response.account.homeAccountId,
      })
    );
  }
}

let fallbackMiddletierToken: string | undefined;

export async function dialogFallback(reqFunction) {
  let isCurrent = false;
  if (fallbackMiddletierToken) {
    const tokenClaims = jose.decodeJwt(fallbackMiddletierToken);
    console.log(tokenClaims);
    console.log(Date.now() / 1_000, tokenClaims.exp - 10, Date.now() / 1_000 < tokenClaims.exp - 10);
    if (Date.now() / 1_000 < tokenClaims.exp - 10) {
      isCurrent = true;
    }
  }
  // Attempt to acquire token silently if user is already signed in.
  if (isCurrent) {
    const response = await reqFunction(fallbackMiddletierToken);
    // console.log("userdata in dialogFallback (cached token):", response);
    return response;
  } else if (homeAccountId !== null) {
    // console.log("homeAccountId was not null");
    const result = await publicClientApp.acquireTokenSilent(loginRequest);
    // console.log("acquireTokenSilent:", result);
    if (result !== null && result.accessToken !== null) {
      fallbackMiddletierToken = result.accessToken;
      const response = await reqFunction(result.accessToken);
      // console.log("userdata in dialogFallback:", response);
      return response;
    }
  } else {
    // console.log("homeAccountId was null");
    // We fall back to Dialog API for any error.
    const url = "/fallbackauthdialog.html";
    const result = await showLoginPopup(url, reqFunction);
    // console.log("showLoginPopup result:", result);
    if (result) {
      return result;
    }
  }
}

// Use the Office dialog API to open a pop-up and display the sign-in page for the identity provider.
function showLoginPopup(url: string, reqFunction): Promise<any> {
  console.log("login popup");
  var fullUrl = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "") + url;
  return new Promise((resolve, reject) => {
    // height and width are percentages of the size of the parent Office application, e.g., PowerPoint, Excel, Word, etc.
    Office.context.ui.displayDialogAsync(fullUrl, { height: 60, width: 30 }, function (result) {
      console.log("Dialog has initialized. Wiring up events");
      loginDialog = result.value;
      loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, async (arg) => {
        // This can trigger multiple times. We don't want to resolve until we
        // get a definitive answer. If we get a falsy response it's likely we
        // need to wait for a later message.
        try {
          const result = await processMessage(arg, reqFunction);
          // No result likely just means more things need to happen in the
          // dialog, so we should wait for another event.
          console.log("processMessage result:", result);
          if (result) {
            resolve(result);
          }
        } catch (err) {
          reject(err);
        }
      });
    });
  });
}

// This handler responds to the success or failure message that the pop-up dialog receives from the identity provider
// and access token provider.
let callCount = 0;
async function processMessage(arg, reqFunction): Promise<any> {
  // Uncomment to view message content in debugger, but don't deploy this way since it will expose the token.
  // console.log("Message received in processMessage: " + JSON.stringify(arg));
  callCount++;
  console.log(`processMessage call number ${callCount}`);
  let messageFromDialog = JSON.parse(arg.message);
  console.log("Message received in processMessage: ", messageFromDialog);
  if (messageFromDialog.status === "success") {
    // We now have a valid access token.
    loginDialog.close();

    // Configure MSAL to use the signed-in account as the active account for future requests.
    const homeAccount = publicClientApp.getAccountByHomeId(messageFromDialog.accountId);
    if (homeAccount) {
      homeAccountId = messageFromDialog.accountId; // Track the account id for future silent token requests.
      publicClientApp.setActiveAccount(homeAccount);
    }
    console.log("calling reqFunction");
    fallbackMiddletierToken = messageFromDialog.result;
    const response = await reqFunction(messageFromDialog.result);
    console.log("userdata in processMessage:", response);
    return response;
  } else if (messageFromDialog.error === undefined && messageFromDialog.result.errorCode === undefined) {
    // Need to pick the user to use to auth
    console.log("need to pick the user to auth");
  } else {
    // Something went wrong with authentication or the authorization of the web application.
    loginDialog.close();
    if (messageFromDialog.error) {
      throw new Error(JSON.stringify(messageFromDialog.error.toString()));
    } else if (messageFromDialog.result) {
      throw new Error(JSON.stringify(messageFromDialog.result.errorMessage.toString()));
    }
  }
}
